import React from 'react';

 const Footer = () => {

    const shoot = (a) => {
        alert(a);
      }   

    return (
    <div className='fixed bottom-0 left-0 w-full bg-white'>
         <span className='text-sm sm:text-center'>© 2022 <button onClick={() => shoot("Goal!")}>Take the shot!</button>.
            All Rights Reserved.
        </span>
        </div>
  )

}

 export default Footer;