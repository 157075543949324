// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UALProvider } from 'ual-reactjs-renderer';
import { Anchor } from 'ual-anchor';
import { Wombat } from 'ual-wombat';
import { Wax } from '@eosdacio/ual-wax';
import './index.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import TransactionPage from './pages/TransactionPage';
import TransactionPage2 from './pages/TransactionPage2';
import TransactionPage3 from './pages/TransactionPage3';
import { initiateSocketConnection } from './components/socket';

const appName = 'NO-APP-DEV-1';

const network = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4', // WAX chain ID
  rpcEndpoints: [
    {
      protocol: 'https',
      host: 'wax.greymass.com',
      port: 443,
    },
  ],
};

const wax = new Wax([network]);
const anchor = new Anchor([network], {appName});
const wombat = new Wombat([network], {appName});

const App = () => {
  
  const [user, setUser] = useState(null);

  useEffect(() => {
   initiateSocketConnection();
  }, []);

 return (
  
<UALProvider chains={[network]} authenticators={[anchor, wax, wombat]} appName={appName}>
      <Router>
     <div className='flex flex-col h-screen justify-between'>
     <Header user={user} setUser={setUser}/>
     <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Transaction" element={<TransactionPage />} />
          <Route path="/Transaction2" element={<TransactionPage2 />} />
          <Route path="/Transaction3" element={<TransactionPage3 />} />
   
      </Routes>
      <Footer />
      </div>
      </Router>
    </UALProvider>
  );
};

export default App;
