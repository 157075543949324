   // src/pages/Home.js
   import React from 'react';
   
         
   const Home = () => {
     return (
       <div className="bg-gray-100 min-h-screen flex mb-auto items-center justify-center">
         <h1 className="text-3xl font-bold underline ">Hello world!</h1>
       </div>
     );
   };

   export default Home;