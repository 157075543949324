 // src/pages/TransactionPage.js
 import React from 'react';
 import Transaction from '../components/Transaction';

 const TransactionPage = () => {
   return (
     <div className="bg-gray-100 min-h-screen flex items-center justify-center">
       <Transaction />
     </div>
   );
 };

 export default TransactionPage;