 // src/pages/TransactionPage3.js
 import React from 'react';
 import TransactionC3 from '../components/Transaction3';
 

 const TransactionPage3 = () => {
  
  
   return (
    <div className='bg-gray-200 min-h-screen w-screen flex items-center justify-center'>
       <div className='min-h-screen w-screen flex items-center justify-center'>
           <TransactionC3 />
        </div>
    </div>
       
     
   );
 };

 export default TransactionPage3;