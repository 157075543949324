// src/Modal.js
import React from 'react';
import './Modal.css';
import Login from './Login';

const CustomModal = ({ isOpen, onRequestClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Hallo, ich bin ein modales Fenster</h2>
        <br />
        <Login />
        <br /><button onClick={onRequestClose}>Schließen</button>
      </div>
    </div>
  );
};

export default CustomModal;