 // src/pages/TransactionPage.js
 import React from 'react';
 import Transaction2 from '../components/Transaction2';
 

 const TransactionPage2 = () => {
  
  
   return (
    <div className='bg-gray-200 min-h-screen w-screen flex items-center justify-center'>
       <div className='min-h-screen w-screen flex items-center justify-center'>
           <Transaction2 />
        </div>
    </div>
       
     
   );
 };

 export default TransactionPage2;