   // src/components/Header.js
   import React from 'react';
   import { Link } from 'react-router-dom';
   import Login from './Login';
   
   const Header = ({ user, setUser }) => {
     return (
       <div className='bg-white px-2 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 border-b border-gray-200'>
         <h1>UAL-Wax React App</h1>
         <Login setUser={setUser} />
         {user && <p>Welcome, {user}</p>}
         <nav>
           <Link to="/">Home</Link>
           <Link to="/transaction">&nbsp;Transaction</Link>
           <Link to="/transaction2">&nbsp;Transaction 2</Link>
           <Link to="/transaction3">&nbsp;Transaction 3</Link>
         </nav>
       </div>
     );
   };

   export default Header;