   // src/components/Transaction.js
   import React, { useState} from 'react';
   import { withUAL } from 'ual-reactjs-renderer';
   import CustomModal from './modal';

   

  const TransactionComponent = ({ ual }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

    const { activeUser } = ual;
       
     const sendTransaction = async () => {
       const { activeUser } = ual;
       if (!activeUser) {
         alert("Please log in first!");
         return;
       } 

       const transaction = {
         actions: [
           {
             account: "eosio",
             name: "delegatebw",
             authorization: [
               {
                 actor: activeUser.accountName,
                 permission: 'active',
               },
             ],
             data: {
                from: activeUser.accountName, // Der Kontoname, von dem aus die Delegierung erfolgt
                receiver: activeUser.accountName, // Der Empfänger der delegierten Bandbreite
                stake_net_quantity: '0.00000001 WAX',
                stake_cpu_quantity: '0.00000000 WAX',
                memo: 'This is a WaxJS/Cloud Wallet Demo.',
                transfer: false
            },
           },
         ],
       };

       try {
         const result = await activeUser.signTransaction(transaction, {
           broadcast: true,
           blocksBehind: 3,
           expireSeconds: 30,
         });
         console.log(result);
       } catch (error) {
         console.error("Transaction failed:", error);
       }
     };
    
        
    return (
        
        <div>
        
        {activeUser ? (
        <div>
        
        <button onClick={sendTransaction}>Send Transaction</button>
        </div>
      ) : (
        <>
        Login to start .. <button onClick={openModal}>Wallet Login</button>
        <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </>
      )}
        </div>
      
      );
    };
 
    export default withUAL(TransactionComponent); 