//import { useState } from 'react';
import { io } from 'socket.io-client';

let socket;

export const initiateSocketConnection = () => {
  if (!socket) {
    
    //socket = io('http://pc-fk:3001', {
      socket = io('https://v99528.1blu.de:3855', {
        transports: ['websocket'],
        withCredentials: true,
    }); // Dein Socket.IO-Server
    console.log('Socket connection established');
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    console.log('Socket disconnected');
  }
};

export const getSocket = () => socket;