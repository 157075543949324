   // src/components/Login.js
   import React, { useState, useEffect } from 'react';
   import { withUAL } from 'ual-reactjs-renderer';
   import { useNavigate } from 'react-router-dom';
   
   //import { disconnectSocket, initiateSocketConnection } from './socket';
     
   const LoginComponent = ({ ual, setUser }) => {
     const [user, setUserState] = useState(null);
     const navigate = useNavigate();
     const [fetchError, setError] = useState(null);

     useEffect(() => {
      
      if (ual.activeUser) {
        ual.activeUser.getAccountName().then(accountName => {
         // initiateSocketConnection();
          setUser(accountName);
           setUserState(accountName);
         });
       }
    }, );
// }, [ual.activeUser]);


const handleLogin = async () => {
  setError(null); // Reset the error state before attempting login
  try {
    await ual.showModal();
  } catch (error) {
    console.error('UAL error:', error);
    setError('Failed to fetch wallet details. Please ensure your wallet is installed and try again.');
  }
};
     const handleLogout = () => {
       ual.logout();
       setUser(null);
       setUserState(null);
      // disconnectSocket();
       //window.location.reload();
       navigate('/');
     };


     return (
       <div>
         {user ? (
           <button onClick={handleLogout}>Logout</button>
         ) : (
          <div>
          <button onClick={handleLogin}>Login with WAX</button>
          
          {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
          
        </div>           
         )}
         
       </div>
     );
   };

   export default withUAL(LoginComponent);