import React, { useState, useEffect, useRef } from 'react';
import { withUAL } from 'ual-reactjs-renderer';
import { Link as LinkTo } from 'react-router-dom';
import { getSocket } from './socket.jsx';
import CustomModal from './modal.jsx';
import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
  

 //const socket = io('https://v99528.1blu.de:3855', {
// const socket = io('localhost:3001', {
  //  secure: true,
//    rejectUnauthorized: true
// });

function ExternalLink({ href, children }) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

const TransactionC2 = ({ ual }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const { activeUser } = ual;
  //console.log(activeUser);
    const notify = (msgtxt, type) => {
        toast(msgtxt, {
            hideProgressBar: true,
            pauseOnHover: false,
            style: {
            backgroundColor: 'black',
            color: type
          }
        });
        };
  
  const hostname = window.location.hostname;
  const [user, setUser] = useState('');
  const [data, setData] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const userLinkRef = useRef();
  const userLink = useRef();
  
  useEffect(() => {
    
    const socket = getSocket();

    if(socket) {
      socket.on('transactionResult', (result) => {
      setResponse(result);
      notify('Transaktion erolgreich.' && result, 'green');
      userLinkRef.current = 'https://wax.bloks.io/transaction/' + result;
      userLink.current = 'https://wax.bloks.io/transaction/' + result;
    });

    socket.on('transactionError', (errorMessage) => {
      setError(errorMessage);
      notify('Es ist ein Fehler aufgetreten.', 'red');
    });

    return () => {
      socket.off('transactionResult');
      socket.off('transactionError');
    };
    }
  }, []);

  const storeData = () => {
    const socket = getSocket();    
    if(socket) {
    try {
    socket.emit('storeData', { user, data, hostname });
    } catch (error) {
      console.log(error);
    }
   
    }
  };

  return (
    <div>

      {activeUser ? (
      
      <>
      <input type="text" value={user} onChange={(e) => setUser(e.target.value)} placeholder="User" />
      <input type="text" value={data} onChange={(e) => setData(e.target.value)} placeholder="Data" />
      <button onClick={storeData}>Store Data</button>
      {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
      <p>
      {userLinkRef.current && <LinkTo to={userLinkRef.current}>Go to Transaction using same Window</LinkTo>}
      </p>
      <p>{userLinkRef.current && <ExternalLink href={userLink.current}>Go to Transaction using new Window </ExternalLink>}</p>
 
      {error && <p style={{ color: 'red' }}>{error}</p>}
      </>
      ) : (
        <>
        Login to start .. <button onClick={openModal}>Wallet Login</button>
        <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </>
      ) 
      }
      <div>
      <ToastContainer />
      </div>      
    
    </div>
  );
};

export default withUAL(TransactionC2);
